import React, { useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const BurgerButton = ({ isScrolling, isMobileNavOpen, setIsMobileNavOpen }) => {
	const handleClickHamburger = () => {
		setIsMobileNavOpen(!isMobileNavOpen);
	};
	const breakpoints = useBreakpoint();

	useEffect(() => {}, [handleClickHamburger]);

	return breakpoints.large ? (
		<button
			className={`button--unstyled burger-button`}
			onClick={handleClickHamburger}
			type="button"
			role="button"
			aria-label="open/close navigation"
		>
			<div
				className={`
					burger-button__line burger-button__line--top 
					${isMobileNavOpen ? `burger-button__line--open` : ``}
					${isScrolling ? `burger-button__line--black` : ``}
				`}
			/>
			<div
				className={`
					burger-button__line burger-button__line--middle 
					${
						isMobileNavOpen
							? `burger-button__line--open burger-button__line--open--middle`
							: ``
					}
					${isScrolling ? `burger-button__line--black` : ``}
				`}
			/>
			<div
				className={`
					burger-button__line burger-button__line--last 
					${
						isMobileNavOpen
							? `burger-button__line--open burger-button__line--open--last`
							: ``
					}
					${isScrolling ? `burger-button__line--black` : ``}
				`}
			/>
		</button>
	) : (
		""
	);
};

export default BurgerButton;
