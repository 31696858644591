import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ seo = {} }) => {
	const { strapiGlobal } = useStaticQuery(query);
	const { defaultSeo, siteName } = strapiGlobal;

	// Merge default and page-specific SEO values
	const fullSeo = { ...defaultSeo, ...seo };

	const getMetaTags = () => {
		const tags = [];

		if (fullSeo.metaTitle) {
			tags.push(
				{
					property: "og:title",
					content: fullSeo.metaTitle,
				},
				{
					name: "twitter:title",
					content: fullSeo.metaTitle,
				}
			);
		}
		if (fullSeo.metaDescription) {
			tags.push(
				{
					name: "description",
					content: fullSeo.metaDescription,
				},
				{
					property: "og:description",
					content: fullSeo.metaDescription,
				},
				{
					name: "twitter:description",
					content: fullSeo.metaDescription,
				}
			);
		}
		if (fullSeo.shareImage) {
			const imageUrl =
				(process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
				fullSeo.shareImage.publicURL;
			tags.push(
				{
					name: "image",
					content: imageUrl,
				},
				{
					property: "og:image",
					content: imageUrl,
				},
				{
					name: "twitter:image",
					content: imageUrl,
				}
			);
		}
		tags.push({ name: "twitter:card", content: "summary_large_image" });

		return tags;
	};

	const metaTags = getMetaTags();

	return (
		<Helmet
			title={fullSeo.metaTitle}
			titleTemplate={`%s | ${siteName}`}
			meta={metaTags}
			htmlAttributes={{
				lang: "fr",
			}}
		/>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

SEO.defaultProps = {
	title: null,
	description: null,
};

const query = graphql`
	query {
		strapiGlobal {
			siteName
			defaultSeo {
				metaTitle
				metaDescription
			}
		}
	}
`;
