import { useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Icon from "@mdi/react";
import { mdiEmailOutline } from "@mdi/js";
import { graphql } from "gatsby";

const Content = () => {
	const data = useStaticQuery(graphql`
		query {
			logoPicture: file(relativePath: { eq: "pedagogie-pnl-logo.png" }) {
				childImageSharp {
					fixed(width: 170) {
						...GatsbyImageSharpFixed_withWebp_noBase64
					}
				}
			}
		}
	`);

	return (
		<div className={`content md:col-span-2`}>
			<Img
				fixed={data.logoPicture.childImageSharp.fixed}
				alt={`Pédagogie PNL - Logo`}
				title={`Pédagogie PNL - Logo`}
				className={`content__logo`}
			/>
			<div className={`content__body`}>
				<h4 className={`content__title`}>Pédagogie PNL</h4>
				<p className={`content__description`}>
					Des outils concrets pour améliorer sa manière d'apprendre.
					<br></br>
					De l'enseignant au coach scolaire, tous contribueront à aider les
					jeunes à dépasser leurs difficultés scolaires, grâce aux stratégies
					PNL d'apprentissage.
				</p>
				<p>
					<Icon path={mdiEmailOutline} size={2} />
					<a href="mailto:info@pedagogiepnl.fr" className={`content__email`}>
						info@pedagogiepnl.fr
					</a>
				</p>
			</div>
		</div>
	);
};

export default Content;
