import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import ButtonAction from "./button-action";

const Nav = ({ isScrolling, isMobileNavOpen }) => {
	const [links, setLinks] = useState([
		{ isActive: false },
		{ isActive: false },
	]);
	const handleClickOutside = () => {
		setLinks([{ isActive: false }, { isActive: false }]);
	};
	const breakpoints = useBreakpoint();

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	});

	return (
		<div className={`${breakpoints.largeUp ? `flex` : ``}`}>
			<nav
				role="navigation"
				className={`nav ${
					!isMobileNavOpen && breakpoints.large ? `nav--hidden` : ``
				}`}
			>
				<ul className={`nav__list`}>
					<li className={`nav__item`}>
						<a
							className={`nav__link nav__link--${
								isScrolling ? `black` : `white`
							}`}
							onClick={(e) => {
								e.stopPropagation();
								setLinks([{ isActive: true }, { isActive: false }]);
							}}
						>
							Pédagogie PNL
							<Icon path={mdiChevronDown} size={1.5} />
						</a>
						<ul
							className={`
							nav__dropdown 
							${breakpoints.large ? `nav__dropdown--full-vw` : `nav__dropdown--big`}
							${
								breakpoints.largeUp
									? links[0].isActive
										? `nav__dropdown--visible`
										: `nav__dropdown--hidden`
									: ``
							}
						`}
						>
							<li>
								<Link
									to={`/la-pedagogie-pnl`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>La pédagogie PNL</span>
								</Link>
							</li>
							<li>
								<Link
									to={`/historique-de-la-pedagogie-pnl`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>
										Historique de la pédagogie PNL
									</span>
								</Link>
							</li>
							<li>
								<Link
									to={`/les-strategies-d-apprentissage-en-pnl`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>
										Les stratégies d'apprentissage en PNL
									</span>
								</Link>
							</li>
							<li>
								<Link
									to={`/ce-que-d-autres-en-disent`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>
										Ce que d'autres en disent
									</span>
								</Link>
							</li>
						</ul>
					</li>
					<li className={`nav__item nav__item--second`}>
						<a
							className={`nav__link nav__link--${
								isScrolling ? `black` : `white`
							}`}
							onClick={(e) => {
								e.stopPropagation();
								setLinks([{ isActive: false }, { isActive: true }]);
							}}
						>
							Ressources
							<Icon path={mdiChevronDown} size={1.5} />
						</a>
						<ul
							className={`
							nav__dropdown 
							${breakpoints.large ? `nav__dropdown--full-vw` : `nav__dropdown--small`}
							${
								breakpoints.largeUp
									? links[1].isActive
										? `nav__dropdown--visible`
										: `nav__dropdown--hidden`
									: ``
							}
						`}
						>
							<li>
								<Link to={`/livres`} className={`nav__link nav__link--second`}>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>Livres</span>
								</Link>
							</li>
							<li>
								<Link to={`/videos`} className={`nav__link nav__link--second`}>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>Vidéos</span>
								</Link>
							</li>
							<li>
								<Link
									to={`/materiel-pedagogique`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>Matériel pédagogique</span>
								</Link>
							</li>
							<li>
								<Link to={`/outils`} className={`nav__link nav__link--second`}>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>Outils</span>
								</Link>
							</li>
							<li>
								<Link
									to={`/liste-des-professionnels-certifies`}
									className={`nav__link nav__link--second`}
								>
									<Icon
										path={mdiChevronRight}
										size={1}
										className={`nav__chevron`}
									/>
									<span className={`nav__link-text`}>
										Liste des professionnels certifiés
									</span>
								</Link>
							</li>
						</ul>
					</li>
				</ul>
			</nav>

			<ButtonAction
				isScrolling={isScrolling}
				isMobileNavOpen={isMobileNavOpen}
			></ButtonAction>
		</div>
	);
};

export default Nav;
