import Icon from "@mdi/react";
import { mdiCommentQuote } from "@mdi/js";
import React from "react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";
require("swiper/swiper-bundle.css");

SwiperCore.use([Autoplay, EffectFade]);

const Testimonials = () => {
	return (
		<div className={`testimonials`}>
			<div className={`flex`}>
				<Swiper
					spaceBetween={0}
					slidesPerView={1}
					effect="fade"
					fadeEffect={{ crossFade: true }}
					speed={1000}
					autoplay={{ delay: 7000 }}
				>
					<SwiperSlide>
						<p className={`testimonials__body`}>
							<Icon
								path={mdiCommentQuote}
								size={7}
								className={`testimonials__quote`}
							/>
							Nous pouvons apprendre tout ce que nous avons décidé d’apprendre.
							Il suffit de savoir comment.
						</p>
						<p className={`testimonials__author`}>Alain Thiry</p>
					</SwiperSlide>
					<SwiperSlide>
						<p className={`testimonials__body`}>
							<Icon
								path={mdiCommentQuote}
								size={7}
								className={`testimonials__quote`}
							/>
							Il n’y a pas d’échecs, que des feedbacks et des apprentissages à
							faire.
						</p>
						<p className={`testimonials__author`}>Bandler et Grinder</p>
					</SwiperSlide>
					<SwiperSlide>
						<p className={`testimonials__body`}>
							<Icon
								path={mdiCommentQuote}
								size={7}
								className={`testimonials__quote`}
							/>
							J’étais trop inquiète pour les notes et j’aurais dû être plus
							inquiète à propos de l’apprentissage.
						</p>
						<p className={`testimonials__author`}>Michèle Obama</p>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
};

export default Testimonials;
