import React from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const ButtonAction = ({ isScrolling, isMobileNavOpen }) => {
	const breakpoints = useBreakpoint();

	return (
		<div
			className={`button-action ${
				!isMobileNavOpen && breakpoints.large ? `button-action--hidden` : ``
			}`}
		>
			<Link to={`/liste-des-formations-sur-la-pedagogie-pnl`} className={`nav__link nav__link--second`}>

				<button
					className={`
								btn
								${isScrolling && breakpoints.largeUp ? `btn--green` : `btn--white`}
								${breakpoints.large ? `btn--green` : ``}
							`}
				>
					Trouver une Formation
				</button>
			</Link>
		</div>
	);
};

export default ButtonAction;
