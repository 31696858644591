import React from "react";
import Content from "./content";
import Testimonials from "./testimonials";

const Footer = () => {
	return (
		<div>
			<div className={`pre-footer`}></div>
			<div className={`footer`}>
				<div className={`container`}>
					<div
						className={`grid grid-cols-1 md:grid md:grid-cols-3 gap-5 flex align-items-center`}
					>
						<Testimonials></Testimonials>
						<Content></Content>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
