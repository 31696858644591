import React from "react";
import PropTypes from "prop-types";
import Nav from "./top-bar/top-bar";
import Seo from "./seo";
import Footer from "../components/footer/footer";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/700.css";

const Layout = ({ children, seo }) => {
	return (
		<>
			<Seo seo={seo} />
			<Nav />
			<main>{children}</main>
			<Footer></Footer>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
