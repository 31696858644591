import React, { useEffect, useState } from "react";
import Logo from "./logo";
import Nav from "./nav";
import BurgerButton from "./burger-button";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const TopBar = () => {
	const [isScrolling, setIsScrolling] = useState(false);
	const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
	const breakpoints = useBreakpoint();

	const isScrollingEvent = () => {
		setIsScrolling(window.scrollY > 50);
	};

	useEffect(() => {
		window.addEventListener("scroll", isScrollingEvent);
		return () => {
			window.removeEventListener("scroll", isScrollingEvent);
		};
	});

	return (
		<div
			className={`
				top-bar
				${isScrolling ? `top-bar--white` : `top-bar--transparent`}
				${isMobileNavOpen ? `top-bar--fullscreen top-bar--white` : ``}
				${isScrolling || breakpoints.largeUp ? `top-bar--transition` : ``}
			`}
		>
			<div
				className={`${
					breakpoints.largeUp ? `flex justify-between` : ``
				} container`}
			>
				<Logo isScrolling={isScrolling} isMobileNavOpen={isMobileNavOpen} />
				<Nav isScrolling={isScrolling} isMobileNavOpen={isMobileNavOpen}></Nav>
				<BurgerButton
					isScrolling={isScrolling}
					isMobileNavOpen={isMobileNavOpen}
					setIsMobileNavOpen={setIsMobileNavOpen}
				></BurgerButton>
			</div>
		</div>
	);
};

export default TopBar;
