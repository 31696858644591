import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";

const Logo = ({ isScrolling, isMobileNavOpen }) => {
	const data = useStaticQuery(graphql`
		query {
			logoPictures: allFile(
				filter: { sourceInstanceName: { eq: "logo" } }
				sort: { fields: name }
			) {
				edges {
					node {
						childImageSharp {
							fluid(srcSetBreakpoints: [200], maxWidth: 200) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div className={`logo`}>
			{data.logoPictures.edges.map((image, index) => {
				return (
					<Link
						to="/"
						className={`link_default`}
						key={image.node.childImageSharp.fluid.src}
					>
						<Img
							fluid={image.node.childImageSharp.fluid}
							alt={`Pédagogie PNL - Logo ${index === 0 ? `blanc` : ``}`}
							title={`Pédagogie PNL - Logo ${index === 0 ? `blanc` : ``}`}
							className={`
							logo__image
							${
								index === 0
									? isScrolling || isMobileNavOpen
										? `logo__image--hidden`
										: `logo__image--visible`
									: isScrolling || isMobileNavOpen
									? `logo__image--visible`
									: `logo__image--hidden`
							}
						`}
						/>
					</Link>
				);
			})}
		</div>
	);
};

export default Logo;
